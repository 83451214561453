

.checkin-btns{
    display: flex;
    flex-direction: column;
    max-width: 200px;
    margin-left: auto;
    label{
        margin-right: 5px;
    }
}
.fv-plugins-message-container.invalid-feedback, .fv-plugins-message-container.valid-feedback {
    display: block;
    font-weight: 400;
}
.sorting{
    cursor: pointer;
}
.sorting .sort-icon i{
    display:  none;
}
.sorting.table-sort-desc .sort-icon i.fa-angle-down {
    display:  initial;
}
.sorting.table-sort-asc .sort-icon i.fa-angle-up {
    display:  initial;
}
.summary-color .symbol-label{
    width: 15px;
    height: 4px;
    background-color: red;
    display: block;
    overflow: hidden;
    border-radius: 4px;
    margin-right: 10px;
}
.mw-100{
    max-width: 100%;
}
.symbol .symbol-avatar{
    width: 90px;
    height: 90px;
}
[data-letters]:before {
    content:attr(data-letters);
    text-transform: uppercase;
    font-family: "Lucida Console";
    display:inline-block;
    font-weight: bolder;
    font-size:2.5em;
    width:2.5em;
    height:2.5em;
    line-height:2.5em;
    text-align:center;
    border-radius:50%;
    background:plum;
    vertical-align:middle;
    // margin-right:1em;
    color:white;
}

.modal-fullscreen .modal-content{
    height: 95%!important;
    margin: 30px;
    .checkout-price{
        height: 85px;
        font-size: 25pt;
        text-align: center;
        font-weight: bolder;
    }
    .numpad {
        height:auto;
        span {
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 32.3333% 1;
            border: 1px solid #D8D8D8;
            background-color: #3487A5!important;
            box-shadow: none;
            box-sizing: border-box;
            color: rgb(8, 8, 8);
            cursor: pointer;
            font-size: 30px;
            line-height: 55px;
            font-weight: 600;
            outline: none;
            padding: 8px 0.8em;
        }
    }
}

@media (min-width: 1440px) {
    .modal-fullscreen .modal-content{
        height: 95%!important;
        margin: 30px;
        .checkout-price{
            height: 85px;
            font-size: 25pt;
            text-align: center;
            font-weight: bolder;
        }
        .numpad {
            height:auto;
            span {
                display: flex;
                align-items: center;
                justify-content: center;
                flex: 32.3333% 1;
                border: 1px solid #D8D8D8;
                background-color: #3487A5!important;
                box-shadow: none;
                box-sizing: border-box;
                color: rgb(8, 8, 8);
                cursor: pointer;
                font-size: 30px;
                line-height: 75px!important;
                font-weight: 600;
                outline: none;
                padding: 8px 0.8em;
            }
        }
    }
}
.text-justify{
    text-align: justify !important;
}
.numpad span {
    background-color: #3487A5!important;
}