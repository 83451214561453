body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  height: 100vh;
  //width: 100vw;
}

.background {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  // z-index: 1;
  background-color: #fff;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  &-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: background-color 0.5s ease-out;
    background-color: transparent;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }

  &-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #0000001a;
  }

  &-footer {
    position: absolute;
    bottom: 0;
    height: 64px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1;
  }
}

.logo {
  user-select: none;
  font-weight: bold;
  cursor: pointer;

  .img {
    height: 65px
  }
}

.img {
  height: 45px
}

.home-page.content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // z-index: 2;
  padding: 10px !important;
}

.Mui-taskbar {
  width: 100%;
  position: absolute;
  bottom: 5px;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
}

.Mui-taskbar>div {

  z-index: 2;
}

.Mui-taskbar>div:first-child {
  // padding-left: 0;
}

.icon-task {
  flex: 0 0 auto;
  color: rgba(0, 0, 0, 0.54);
  padding: 12px 6px;
  overflow: visible;
  font-size: 1.6rem;
  font-weight: bold;
  color: #fff;
  text-align: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
  position: relative;
  align-items: center;
  border: 0;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
}

.icon-label {
  width: 100%;
  display: flex;
  align-items: inherit;
  justify-content: inherit;
  width: 24px;
  margin: 0 5px;
}
#kt_modal_create_booking{
  .modal-content {
    background: rgba(255, 199, 0, 0.16) !important;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom: 1px solid #e34814;
    box-shadow: 0px 1px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  }
}


.sapa-overlay-panel-sliding {
  display: flex;
  justify-content: center;

  .slide-pane__header {
    background: rgba(0, 0, 0, 0.6) !important;
    flex-direction: row-reverse;

    .slide-pane__title {
      color: white;
    }

    .slide-pane__close {
      color: white;
      font-size: 30px;
    }
  }

  .sapa-panel-sliding {
    height: 40vh !important;
    margin-top: calc(60vh - 65px) !important;
    margin-bottom: 65px !important;
    background: rgba(0, 0, 0, 0.3) !important;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom: 1px solid #e34814;
    box-shadow: 0px 1px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  }

 
  .gallery-panel-sliding {
    height:52vh !important;
    margin-top: calc(48vh - 65px) !important;
    .lg-react-element {
      height: calc(52vh - 20px);
      overflow-y: auto;
    }
    img.center-cropped{
      object-fit: cover;
      object-position: center;
      height: 160px;
      width: 95%;
      cursor: pointer;
      margin-bottom: 5px;;
    }

    img.nature {
      
      //background: transparent;
      //padding: 8px;
      border: 1px solid #ccc;
      //box-shadow: 10px 10px 10px #999;
    }
  }
  
  
  
  .info-panel-sliding {
    height: 58vh !important;
    margin-top: calc(42vh - 65px) !important;

    .card {
      background: transparent;
    }

    .symbol>img {
      width: 60px;
      height: 60px;
    }

  }

  .slide-pane__content {
    position: relative;
    overflow-y: unset;
    padding: 15px 10px;
    flex: 1 1 auto;

  }

  .slide-pane__content p {
    color: #ffffff;
    font-size: 15px;
    padding: 10px 8px;
  }

  .about-panel-sliding {
    height: 75vh !important;
    margin-top: calc(25vh - 65px) !important;
  }

  .service-panel-sliding {
    height: 75vh !important;
    margin-top: calc(25vh - 65px) !important;
    background-color: #ffffff !important;
    width: 100vw;
    // .card{
    //   height: calc(75vh - 30px);
    // }
    // .nav-line-tabs .nav-item .nav-link.active{
    //   color:#009ef7;
    //   font-weight: bold;
    // }

    // .table-responsive{
    //   overflow-y: scroll;
    //   height: calc(60vh - 30px);
    // }
    .nav-item {
      color: #009ef7;
      font-weight: bold;
      font-size: 1.2rem;

      .nav-link.active {
        color: #009ef7 !important;
        ;
      }
    }

    .tab-pane {
      overflow: auto;
      height: calc(67vh - 30px);
      padding: 5px 10px;
      font-size: 1rem;
      background-color: #f5f8fa !important;
    }
  }

  @media (min-width: 767px) {
    .about-panel-sliding {
      height: 43vh !important;
      margin-top: calc(57vh - 65px) !important;
      .vertical-center {
        margin: 0;
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
      }
    }

    .service-panel-sliding {
      height: 60vh !important;
      margin-top: calc(40vh - 65px) !important;
      width: 70vw !important;
      table{
        margin: auto;
        min-width: 350px;
      }
      .nav-item {
        color: #009ef7;
        font-weight: bold;
        font-size: 1.4rem;

        .nav-link.active {
          color: #009ef7 !important;
          ;
        }
      }

      .tab-pane {
        overflow: auto;
        height: calc(57vh - 45px);
        padding: 10px 20px;
        font-size: 1.2rem;
        background-color: #f5f8fa !important;
      }
    }

    .gallery-panel-sliding{
      height: 72vh !important;
      margin-top: calc(28vh - 65px) !important;
      width: 70vw;
      img.center-cropped{
        object-fit: cover;
        object-position: center;
        height: 220px !important;
        width: 19% !important;
        cursor: pointer;
        margin-bottom: 5px;;
      }      
    }
    .gallery-container,
    .lg-react-element {
      height: calc(72vh - 20px) !important;
      overflow-y: auto;
    }
    
  }
}



.topnav-container {
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
}

.topnav-center {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.topnav-logo,
.topnav-time {
  font-weight: bold;
  font-size: 1.25em;
  text-align: center;
}

.flex-container {
  padding: 0;
  margin: 0;
  list-style: none;
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
}

.wrap {
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.wrap li {
  background: transparent;
}

.flex-item {

  width: 250px;
  height: 140px;
  margin: 2px;
}

.navi-icon .svg-icon img {
  padding-bottom: 7px;
}


.gallery-item {
  margin: 5px;
}


.time-list .time-items {
  padding: 0;
  display: flex;
  flex-wrap: wrap;

  .btn-check:checked+.btn.btn-light {
    background-color: #009ef7 !important;

    div {
      color: #FFFFFF !important;
    }
  }

  .btn-check:checked+label {
    color: #FFFFFF !important;
  }
}

.time-list label {
  display: block;
}

.time-list .time-items label.disabled {
  background-color: #ddd;
  color: #333;
  cursor: default;
}

.time-list .time-items label {
  width: 75px;
  text-align: center;
  border: 1px solid #ececec !important;
  padding: 5px 10px !important;
  display: flex;
  justify-content: center;
  line-height: 1;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(25% - 2px);
  margin: 5px 5px 0 5px;
  cursor: pointer;
  white-space: nowrap;

}

.bg-odd {
  background-color: #f4f5f6!important;
}

.bg-even {
  background-color: #f0f0f0!important;
}