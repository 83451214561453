//
// Page loader
//

// CSS3 Transitions only after page load(.page-loading class added to body tag and remove with JS on page load)
.page-loading * {
  transition: none !important;
}
.page-loading .loading-bg{
  display: block;
}
// Base
.page-loader {
  background: #fff;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: none;

  .page-loading & {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.loading-bg {
  background: rgba(0,0,0,0.1);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  display: none;
  img{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
